import React from "react";
import useContact from "../hooks/useContact";

const contact_content = [
  {
    icon: (
      <svg width="32" height="33" viewBox="0 0 32 33" fill="none">
        <path
          d="M16 16.8739C16.7333 16.8739 17.3611 16.6128 17.8833 16.0905C18.4055 15.5683 18.6666 14.9405 18.6666 14.2072C18.6666 13.4739 18.4055 12.8461 17.8833 12.3239C17.3611 11.8016 16.7333 11.5405 16 11.5405C15.2666 11.5405 14.6389 11.8016 14.1166 12.3239C13.5944 12.8461 13.3333 13.4739 13.3333 14.2072C13.3333 14.9405 13.5944 15.5683 14.1166 16.0905C14.6389 16.6128 15.2666 16.8739 16 16.8739ZM16 26.6739C18.7111 24.185 20.7222 21.9239 22.0333 19.8905C23.3444 17.8572 24 16.0516 24 14.4739C24 12.0516 23.2278 10.0683 21.6833 8.52386C20.1389 6.97942 18.2444 6.20719 16 6.20719C13.7555 6.20719 11.8611 6.97942 10.3166 8.52386C8.7722 10.0683 7.99998 12.0516 7.99998 14.4739C7.99998 16.0516 8.65553 17.8572 9.96665 19.8905C11.2778 21.9239 13.2889 24.185 16 26.6739ZM16 30.2072C12.4222 27.1628 9.74998 24.335 7.98331 21.7239C6.21665 19.1128 5.33331 16.6961 5.33331 14.4739C5.33331 11.1405 6.40554 8.48497 8.54998 6.50719C10.6944 4.52942 13.1778 3.54053 16 3.54053C18.8222 3.54053 21.3055 4.52942 23.45 6.50719C25.5944 8.48497 26.6666 11.1405 26.6666 14.4739C26.6666 16.6961 25.7833 19.1128 24.0166 21.7239C22.25 24.335 19.5778 27.1628 16 30.2072Z"
          fill="var(--yellow-tint-5)"
        />
      </svg>
    ),
    title: ["Visit", "us at"],
    content:
      "Suite E9 SHM Complex, Mabushi, Abuja Nigeria.\nLevel 54 Almas tower, jumeirah lake tower Dubai, UAE",
  },
  {
    icon: (
      <svg width="32" height="33" viewBox="0 0 32 33" fill="none">
        <path
          d="M26.6 28.874C23.7333 28.874 20.9389 28.2351 18.2167 26.9574C15.4944 25.6796 13.0833 23.9907 10.9833 21.8907C8.88333 19.7907 7.19444 17.3796 5.91667 14.6574C4.63889 11.9351 4 9.14069 4 6.27402C4 5.87402 4.13333 5.54069 4.4 5.27402C4.66667 5.00736 5 4.87402 5.4 4.87402H10.8C11.1111 4.87402 11.3889 4.97402 11.6333 5.17402C11.8778 5.37402 12.0222 5.62958 12.0667 5.94069L12.9333 10.6074C12.9778 10.9185 12.9722 11.2018 12.9167 11.4574C12.8611 11.7129 12.7333 11.9407 12.5333 12.1407L9.3 15.4074C10.2333 17.0074 11.4056 18.5074 12.8167 19.9074C14.2278 21.3074 15.7778 22.5185 17.4667 23.5407L20.6 20.4074C20.8 20.2074 21.0611 20.0574 21.3833 19.9574C21.7056 19.8574 22.0222 19.8296 22.3333 19.874L26.9333 20.8074C27.2444 20.874 27.5 21.024 27.7 21.2574C27.9 21.4907 28 21.7629 28 22.074V27.474C28 27.874 27.8667 28.2074 27.6 28.474C27.3333 28.7407 27 28.874 26.6 28.874ZM8.03333 12.874L10.2333 10.674L9.66667 7.54069H6.7C6.81111 8.4518 6.96667 9.3518 7.16667 10.2407C7.36667 11.1296 7.65556 12.0074 8.03333 12.874ZM19.9667 24.8074C20.8333 25.1851 21.7167 25.4851 22.6167 25.7074C23.5167 25.9296 24.4222 26.074 25.3333 26.1407V23.2074L22.2 22.574L19.9667 24.8074Z"
          fill="var(--yellow-tint-5)"
        />
      </svg>
    ),
    title: ["Call", "us on"],
    content: "+234 903 792 0018, +971 585 814 569",
  },
  {
    icon: (
      <svg width="32" height="33" viewBox="0 0 32 33" fill="none">
        <path
          d="M5.33332 27.5409C4.59999 27.5409 3.97221 27.2797 3.44999 26.7575C2.92777 26.2353 2.66666 25.6075 2.66666 24.8742V8.87419C2.66666 8.14085 2.92777 7.51307 3.44999 6.99085C3.97221 6.46863 4.59999 6.20752 5.33332 6.20752H26.6667C27.4 6.20752 28.0278 6.46863 28.55 6.99085C29.0722 7.51307 29.3333 8.14085 29.3333 8.87419V24.8742C29.3333 25.6075 29.0722 26.2353 28.55 26.7575C28.0278 27.2797 27.4 27.5409 26.6667 27.5409H5.33332ZM16 18.2075L5.33332 11.5409V24.8742H26.6667V11.5409L16 18.2075ZM16 15.5409L26.6667 8.87419H5.33332L16 15.5409ZM5.33332 11.5409V8.87419V24.8742V11.5409Z"
          fill="var(--yellow-tint-5)"
        />
      </svg>
    ),
    title: ["Email", "us at"],
    content: "info@icart.com",
  },
];

const Input = ({ label, type, name, textarea, ...props }) => (
  <div className="form_control">
    <p>{label}</p>
    {textarea ? (
      <textarea type={type} name={name} {...props} required />
    ) : (
      <input type={type} name={name} {...props} required />
    )}
  </div>
);

export const Contact = () => {
  const { contact, isLoading } = useContact();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
      email: formData.get("email"),
      firstName: formData.get("fname"),
      lastName: formData.get("lname"),
      phoneNumber: formData.get("number"),
      message: formData.get("message"),
    };
    contact(data);
  };

  return (
    <div className="contact_container" id="contact">
      <section>
        <div className="contact">
          <div className="content_header-2">
            <h2>Contact Us</h2>
            <p className="contact_desc">
              Get in touch with us for any enquiries and custom requests for a
              concept
            </p>
          </div>
          <div className="contact_cards">
            {contact_content.map((content, uid) => (
              <div className="contact_card-content" key={uid}>
                <div className="contact_card-icon">{content.icon}</div>
                <h4>
                  <span>{content.title[0]}</span>
                  {content.title[1]}
                </h4>
                <p className="justify">{content.content}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="contact_form">
          <div className="form">
            <svg
              className="contact_star star one"
              width="53"
              height="53"
              viewBox="0 0 53 53"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.1838 0H26.1841V2.67029e-05C26.1841 14.4726 37.9163 26.2049 52.3888 26.205C37.9163 26.2051 26.1841 37.9375 26.1841 52.41C26.184 52.41 26.1839 52.41 26.1838 52.41L26.1835 52.41C26.1835 37.9445 14.4628 26.2166 -1.33514e-05 26.205C14.4628 26.1934 26.1835 14.4655 26.1835 2.67029e-05V0H26.1838Z"
                fill="var(--yellow-tint-5)"
              />
            </svg>
            <svg
              className="contact_star star two"
              width="53"
              height="53"
              viewBox="0 0 53 53"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.1838 0H26.1841V2.67029e-05C26.1841 14.4726 37.9163 26.2049 52.3888 26.205C37.9163 26.2051 26.1841 37.9375 26.1841 52.41C26.184 52.41 26.1839 52.41 26.1838 52.41L26.1835 52.41C26.1835 37.9445 14.4628 26.2166 -1.33514e-05 26.205C14.4628 26.1934 26.1835 14.4655 26.1835 2.67029e-05V0H26.1838Z"
                fill="var(--yellow-tint-2)"
              />
            </svg>
            <svg
              className="contact_star star three"
              width="53"
              height="53"
              viewBox="0 0 53 53"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.1838 0H26.1841V2.67029e-05C26.1841 14.4726 37.9163 26.2049 52.3888 26.205C37.9163 26.2051 26.1841 37.9375 26.1841 52.41C26.184 52.41 26.1839 52.41 26.1838 52.41L26.1835 52.41C26.1835 37.9445 14.4628 26.2166 -1.33514e-05 26.205C14.4628 26.1934 26.1835 14.4655 26.1835 2.67029e-05V0H26.1838Z"
                fill="var(--yellow-tint-2)"
              />
            </svg>
            <svg
              className="contact_star star four"
              width="53"
              height="53"
              viewBox="0 0 53 53"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.1838 0H26.1841V2.67029e-05C26.1841 14.4726 37.9163 26.2049 52.3888 26.205C37.9163 26.2051 26.1841 37.9375 26.1841 52.41C26.184 52.41 26.1839 52.41 26.1838 52.41L26.1835 52.41C26.1835 37.9445 14.4628 26.2166 -1.33514e-05 26.205C14.4628 26.1934 26.1835 14.4655 26.1835 2.67029e-05V0H26.1838Z"
                fill="var(--yellow-tint-3)"
              />
            </svg>
            <div className="content_header-2">
              <h2>Send Us a Message</h2>
              <p>
                Get in touch with us for any enquiries and custom requests for a
                concept
              </p>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="inputs_container">
                <Input
                  label="First name"
                  placeholder="Enter your first name"
                  type="text"
                  name="fname"
                />
                <Input
                  label="Last name"
                  placeholder="Enter your last name"
                  type="text"
                  name="lname"
                />
                <Input
                  label="Email"
                  placeholder="username@email.com"
                  type="email"
                  name="email"
                />
                <Input
                  label="Phone number"
                  placeholder="+234 801 234 5678"
                  type="text"
                  name="number"
                />
              </div>
              <Input
                label="Message"
                placeholder="Enter your message"
                type="text"
                name="message"
                textarea
              />
              <button disabled={isLoading} className="form_btn" type="submit">
                {isLoading ? "Loading..." : "Send Message"}
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};
