import { toast } from "react-toastify";
import useClient from "./useClient";
import urls from "../api/urls";

const useContact = () => {
  const { post, isLoading, errorMessage } = useClient();

  const subscribe = async (details) => {
    const { data, error } = await post(urls.news.baseUrl, details);
    if (error) return;
    toast.success(data.message);
  };

  const contact = async (details) => {
    const { data, error } = await post(urls.contact.baseUrl, details);
    if (error) return;
    toast.success(data.message);
  };

  return {
    isLoading,
    subscribe,
    contact,
    errorMessage,
  };
};

export default useContact;
