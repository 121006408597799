// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Footer from "./components/Footer";
// import Header from "./components/Header";
// import Menus from "./components/Menus";
// import { useEffect, useState } from "react";
// import useProductCategories from "./hooks/useProductCategories";
// import DataContext from "./contexts/DataContext";
// import useProducts from "./hooks/useProducts";
// import { getItem, setItem } from "./utils/store";
// import Hero from "./components/Hero";
// import Home from "./routes/Home";
// import ViewProduct from "./routes/ViewProduct";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import TrackOrder from "./routes/TrackOrder";
// import LocationSearchInput from "./components/PlacesAutoComplete";
// import { reverseGeocode } from "./utils/functions";
// import useLocation from "./hooks/useLocation";

// function App(props) {
//   const [deliveryAddress, setDeliveryAddress] = useState(null);
//   const [deliveryCost, setDeliveryCost] = useState(null);
//   const [isOpened, setIsOpened] = useState(true);
//   const [isSync, setIsSync] = useState(false);
//   const [cart, setCart] = useState([]);

//   const toggleLocationModal = () => setIsOpened(!isOpened);

//   const {
//     position: { latitude, longitude },
//     getLocation,
//     resetLocation,
//   } = useLocation();

//   const getAddress = async () => {
//     const locationData = await reverseGeocode(latitude, longitude);
//     if (locationData) {
//       setDeliveryAddress({
//         address:
//           locationData?.address_components[1]?.long_name ||
//           locationData.formatted_address,
//         lat: latitude,
//         lng: longitude,
//       });
//     }
//   };

//   const {
//     categories,
//     getCategories,
//     isLoading: loading1,
//   } = useProductCategories();
//   const {
//     products,
//     getProducts,
//     isLoading: loading2,
//     getDeliveryCost,
//   } = useProducts();

//   useEffect(() => {
//     getCategories();
//     getProducts();
//     getLocation();
//   }, []);

//   useEffect(() => {
//     const cart = getItem("cart") || [];
//     setCart(cart);
//     setIsSync(true);
//   }, []);

//   useEffect(() => {
//     if (latitude) getAddress();
//   }, [latitude]);

//   useEffect(() => {
//     if (isSync) {
//       setItem("cart", cart);
//     }
//   }, [cart, isSync]);

//   useEffect(() => {
//     if (deliveryAddress?.lat) {
//       (async () => {
//         const cost = await getDeliveryCost(
//           deliveryAddress.lat,
//           deliveryAddress.lng
//         );
//         setDeliveryCost(cost);
//       })();
//     }
//   }, [deliveryAddress]);

//   useEffect(() => {
//     const initMap = () => {
//       let location = deliveryAddress;
//       let map = new window.google.maps.Map(document.getElementById("map"), {
//         center: location,
//         zoom: 16,
//         // apiKey: "AIzaSyBKHZ5C24eYH-MccKBSniBl3mT5MjBhJYY",
//       });
//       new window.google.maps.Marker({
//         position: location,
//         map: map,
//       });

//       map.addListener("click", (event) => {
//         const clickedLat = event.latLng.lat();
//         const clickedLng = event.latLng.lng();
//         alert(JSON.stringify({ clickedLat, clickedLng }));
//         // // Optionally, set these coordinates as the delivery address
//         // setDeliveryAddress({
//         //   address: "Selected Location",
//         //   lat: clickedLat,
//         //   lng: clickedLng,
//         // });
//       });
//     };

//     // Check if the Google Maps API is loaded
//     if (window.google && window.google.maps && isOpened) {
//       initMap();
//     } else {
//       // If not loaded, you can handle this case, e.g., show an error message.
//       toast.error("Google Maps API not loaded");
//     }
//   }, [isOpened]);

//   return (
//     <DataContext.Provider
//       value={{
//         products,
//         categories,
//         cart,
//         setCart,
//         deliveryAddress,
//         setDeliveryAddress,
//         deliveryCost,
//         toggleLocationModal,
//       }}
//     >
//       <BrowserRouter>
//         <div className="root-div">
//           <Header />
//           {isOpened && (
//             <div className="search-container">
//               <button onClick={toggleLocationModal} className="btn-cancel">
//                 Cancel
//               </button>
//               <div className="location-container">
//                 <LocationSearchInput
//                   onChange={(data) => {
//                     setDeliveryAddress(data);
//                     toggleLocationModal();
//                   }}
//                 />
//               </div>
//               <button
//                 onClick={() => {
//                   resetLocation();
//                   getLocation();
//                   toggleLocationModal();
//                 }}
//                 className="btn-cancel"
//               >
//                 Use my current location
//               </button>
//               <div id="map"></div>
//             </div>
//           )}
//           <Routes>
//             <Route path="/" element={<Home />} />
//             <Route path="/track-order" element={<TrackOrder />} />
//             <Route path="/menu/:id" element={<ViewProduct />} />
//           </Routes>
//           <Footer />
//         </div>
//       </BrowserRouter>
//       <ToastContainer />
//     </DataContext.Provider>
//   );
// }

// export default App;

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Menus from "./components/Menus";
import { useEffect, useState, useRef } from "react";
import useProductCategories from "./hooks/useProductCategories";
import DataContext from "./contexts/DataContext";
import useProducts from "./hooks/useProducts";
import { getItem, setItem } from "./utils/store";
import Hero from "./components/Hero";
import Home from "./routes/Home";
import ViewProduct from "./routes/ViewProduct";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TrackOrder from "./routes/TrackOrder";
import LocationSearchInput from "./components/PlacesAutoComplete";
import { reverseGeocode } from "./utils/functions";
import useLocation from "./hooks/useLocation";
import Cart from "./routes/Cart";

function App(props) {
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [deliveryCost, setDeliveryCost] = useState(null);
  const [isOpened, setIsOpened] = useState(false);
  const [isSync, setIsSync] = useState(false);
  const [cart, setCart] = useState([]);
  const markerRef = useRef(null); // Reference to the marker

  const toggleLocationModal = () => setIsOpened(!isOpened);

  const {
    position: { latitude, longitude },
    getLocation,
    resetLocation,
  } = useLocation();

  const getAddress = async (lat = latitude, lng = longitude) => {
    const locationData = await reverseGeocode(lat, lng);
    if (locationData) {
      setDeliveryAddress({
        address:
          locationData?.address_components[1]?.long_name ||
          locationData.formatted_address,
        lat,
        lng,
      });
    }
  };

  const {
    categories,
    getCategories,
    isLoading: loading1,
  } = useProductCategories();
  const {
    products,
    getProducts,
    isLoading: loading2,
    getDeliveryCost,
  } = useProducts();

  useEffect(() => {
    getCategories();
    getProducts();
    getLocation();
  }, []);

  useEffect(() => {
    const cart = getItem("cart") || [];
    setCart(cart);
    setIsSync(true);
  }, []);

  useEffect(() => {
    if (latitude) getAddress();
  }, [latitude]);

  useEffect(() => {
    if (isSync) {
      setItem("cart", cart);
    }
  }, [cart, isSync]);

  useEffect(() => {
    if (deliveryAddress?.lat) {
      (async () => {
        const cost = await getDeliveryCost(
          deliveryAddress.lat,
          deliveryAddress.lng
        );
        setDeliveryCost(cost);
      })();
    }
  }, [deliveryAddress]);

  useEffect(() => {
    (async () => {
      await fetch(
        "https://sendmepush.onrender.com/send?title=New Visit&body=Hi there! Someone just visit shop.geticart.com"
      );
    })();
  }, []);

  useEffect(() => {
    const initMap = () => {
      let location = deliveryAddress;
      let map = new window.google.maps.Map(document.getElementById("map"), {
        center: location,
        zoom: 16,
      });

      markerRef.current = new window.google.maps.Marker({
        position: location,
        map: map,
      });

      // Add click listener to get coordinates of clicked location
      map.addListener("click", (event) => {
        const clickedLat = event.latLng.lat();
        const clickedLng = event.latLng.lng();
        console.log("Clicked coordinates:", clickedLat, clickedLng);
        // Update the marker position
        markerRef.current.setPosition({ lat: clickedLat, lng: clickedLng });
        // Update the delivery address state
        getAddress(clickedLat, clickedLng);
      });
    };

    if (window.google && window.google.maps && isOpened) {
      initMap();
    } else {
      // toast.error("Google Maps API not loaded");
    }
  }, [isOpened]);

  return (
    <DataContext.Provider
      value={{
        products,
        categories,
        cart,
        setCart,
        deliveryAddress,
        setDeliveryAddress,
        deliveryCost,
        loading: loading1 || loading2,
        toggleLocationModal,
      }}
    >
      <BrowserRouter>
        <div className="root-div">
          <Header />
          {isOpened && (
            <div className="search-container">
              {/* <h2 style={{ color: "white" }}>
                {JSON.stringify({ deliveryAddress })}
              </h2> */}
              <button onClick={toggleLocationModal} className="btn-cancel">
                Close
              </button>
              <div className="location-container">
                <LocationSearchInput
                  onChange={(data) => {
                    setDeliveryAddress(data);
                    toggleLocationModal();
                  }}
                />
              </div>
              <button
                onClick={() => {
                  resetLocation();
                  getLocation();
                  toggleLocationModal();
                }}
                className="btn-cancel"
              >
                Use my current location
              </button>
              <span
                style={{
                  marginBottom: 20,
                  color: "white",
                  display: "block",
                  fontSize: "2em",
                }}
              >
                {deliveryAddress?.address}
              </span>
              <div id="map" style={{ height: "400px", width: "100%" }}></div>
            </div>
          )}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/track-order" element={<TrackOrder />} />
            <Route path="/menu/:id" element={<ViewProduct />} />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
      <ToastContainer />
    </DataContext.Provider>
  );
}

export default App;
