import { Contact } from "../components/Contact";
import Menus from "../components/Menus";
import Hero from "../components/Hero";

function Home(props) {
  return (
    <div>
      <Hero />
      <Menus />
      <Contact />
    </div>
  );
}

export default Home;
