import UCart from "../components/Cart";

function Cart(props) {
  return (
    <div>
      <div style={{ height: 100 }} />
      <UCart />
      <div style={{ height: 100 }} />
    </div>
  );
}

export default Cart;
