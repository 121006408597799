import { useState, useEffect } from "react";
import "../css/header.css";
import logoDark from "../assets/svgs/logo-dark.svg";
import logoLight from "../assets/svgs/logo-light.svg";
import { NavLink, useLocation } from "react-router-dom";
import { nav_links } from "../constants";

const NavLinks = ({
  noHash,
  hash,
  hasPath,
  styleName,
  setActiveNav,
  activeNav,
  mobile,
}) => {
  return (
    <>
      {noHash?.map((path) => (
        <NavLink
          key={path.text}
          to={path.path}
          onClick={() => mobile && setActiveNav(!activeNav)}
          className={({ isActive }) =>
            isActive ? `${styleName} activeLink` : `${styleName}`
          }
        >
          {path.text}
        </NavLink>
      ))}
      {hash?.map((hash) =>
        hasPath ? (
          <NavLink
            key={hash.text}
            to={"/"}
            onClick={() => mobile && setActiveNav(!activeNav)}
            className={({ isActive }) =>
              isActive ? `${styleName}activeLink` : `${styleName}`
            }
          >
            {hash.text}
          </NavLink>
        ) : (
          <a
            href={hash.path}
            onClick={() => mobile && setActiveNav(!activeNav)}
            key={hash.text}
            className={styleName}
          >
            {hash.text}
          </a>
        )
      )}
    </>
  );
};

const Header = () => {
  const { pathname } = useLocation();
  const [end, setEnd] = useState(0);
  const [navbarTop, setNavbarTop] = useState(0);
  const [navColor, setNavColor] = useState(true);
  const [activeNav, setActiveNav] = useState(true);

  useEffect(() => {
    if (pathname === "/") {
      setNavColor(true);
    } else {
      setNavColor(false);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageXOffset || document.documentElement.scrollTop;
      if (scrollTop > end) {
        setNavbarTop("-100%");
      } else if (scrollTop <= end) {
        setNavbarTop("0");
      }
      setEnd(scrollTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [end]);

  const hasPath = pathname !== "/";

  return (
    <header
      className={navColor ? "" : "activeHeader"}
      style={{ top: navbarTop }}
    >
      <section
        style={{
          borderBottomColor: navColor
            ? "var(--yellow-tint-5)"
            : "var(--black-tint-2)",
        }}
      >
        <div
          className={activeNav ? "mobile_nav mobile_nav-active" : "mobile_nav"}
        >
          <ul className="nav_links-mobile">
            {nav_links.map((link, uid) => {
              const hash = link.hash,
                noHash = link.no_hash;

              return (
                <NavLinks
                  key={uid}
                  hash={hash}
                  noHash={noHash}
                  hasPath={hasPath}
                  styleName="nav_links-mobile_link"
                  setActiveNav={setActiveNav}
                  activeNav={activeNav}
                  mobile
                />
              );
            })}
          </ul>

          <hr />

          <NavLink
            className="mobile_nav-btn"
            onClick={() => setActiveNav(!activeNav)}
          >
            Go to dashboard
          </NavLink>
        </div>

        <nav>
          <NavLink to={"/"} className="logo">
            <img src={navColor ? logoLight : logoDark} alt="ICart logo" />
          </NavLink>

          {/* <div className="input-cnt">
            <input
              className="input"
              placeholder="Search..."
              type="text"
              name="search"
              id="search"
            />
          </div> */}
          <ul className="nav_links">
            {nav_links.map((link, uid) => {
              const hash = link.hash,
                noHash = link.no_hash;

              return (
                <NavLinks
                  key={uid}
                  hash={hash}
                  noHash={noHash}
                  hasPath={hasPath}
                  styleName="nav_links-link"
                />
              );
            })}
          </ul>

          <div className="nav_right">
            <NavLink className="nav_btn">Go to iCart Africa</NavLink>

            <div
              className="nav_hamburger"
              onClick={() => {
                setActiveNav(!activeNav);
                setNavColor(!navColor);
              }}
            >
              {activeNav ? (
                <svg width="24" height="16" viewBox="0 0 24 16" fill="none">
                  <path
                    d="M0 16V13.3333H24V16H0ZM0 9.33333V6.66667H24V9.33333H0ZM0 2.66667V0H24V2.66667H0Z"
                    fill={navColor ? "var(--white-main)" : "var(--black-main)"}
                  />
                </svg>
              ) : (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M2.53329 19.3332L0.666626 17.4665L8.13329 9.99984L0.666626 2.53317L2.53329 0.666504L9.99996 8.13317L17.4666 0.666504L19.3333 2.53317L11.8666 9.99984L19.3333 17.4665L17.4666 19.3332L9.99996 11.8665L2.53329 19.3332Z"
                    fill={navColor ? "var(--white-main)" : "var(--black-main)"}
                  />
                </svg>
              )}
            </div>
          </div>
        </nav>
      </section>
    </header>
  );
};

export default Header;
