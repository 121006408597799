import { useContext, useEffect, useRef, useState } from "react";
import "../css/menus.css";
import { capitalizeFirstLetter, formatNumber } from "../utils/functions";
import { Link, useLocation } from "react-router-dom";
import Image from "./Image";
import DataContext from "../contexts/DataContext";

const Icon = ({ img }) => {
  return (
    <div className="icon flex justify-center align-center">
      <Image style={{ borderRadius: "50%" }} src={img} />
    </div>
  );
};

const Category = ({ title, onSelect, icon, active = false }) => {
  return (
    <div
      onClick={onSelect}
      className={`flex category align-center ${active ? "active" : ""}`}
    >
      <Icon img={icon} />
      <span>{capitalizeFirstLetter(title)}</span>
    </div>
  );
};

const Item = ({ name, image, description, price, inStock, id, ...props }) => {
  return (
    <Link
      to={`/menu/${id}`}
      state={{
        item: {
          ...props,
          name,
          image,
          description,
          price,
          id,
          inStock,
        },
      }}
      className="card menu-item"
    >
      {!inStock ? <span className="sold-out">sold out</span> : null}
      <div className="img-card">
        <Image src={image} alt={description} />
      </div>
      <div className="details">
        <h4>{capitalizeFirstLetter(name)}</h4>
        <span style={{ color: "var(--grey)" }}>{description}</span>
        <span className="price">{formatNumber(price)}</span>
      </div>
    </Link>
  );
};

function Menus(props) {
  const { products, categories, loading } = useContext(DataContext);
  const [menu, setMenu] = useState(null);
  const { hash } = useLocation();
  const cnt = useRef(null);

  useEffect(() => {
    if (categories.length) setMenu(categories[0]._id);
  }, [categories]);

  useEffect(() => {
    if (hash === "#menus") cnt?.current?.scrollIntoView({ behavior: "smooth" });
  }, [hash]);

  return (
    <>
      <h2 className="align-center text-primary">Explore the Menu! 😋</h2>
      <br />
      <div className="container menus-container  flex mobile-column">
        {loading ? (
          <div
            className="flex container justify-center align-center"
            style={{ height: 150 }}
          >
            <span style={{ color: "white" }}>loading...</span>
          </div>
        ) : (
          <>
            <div ref={cnt} className="categories">
              {categories.map((m, idx) => (
                <Category
                  icon={m.imageUrl}
                  active={menu === m._id}
                  onSelect={() => setMenu(m._id)}
                  key={idx}
                  title={m.name}
                />
              ))}
            </div>
            <div className="items">
              <div className="list flex">
                {products
                  .filter((p) => p.category === menu)
                  ?.map((a, idx) => (
                    <Item
                      id={a._id}
                      key={idx}
                      inStock={a.inStock}
                      image={a.imageUrl}
                      description={a.description}
                      name={a.name}
                      price={a.price}
                    />
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Menus;
