import axios from "axios";
import store from "../utils/store";

const client = axios.create({
  baseURL: "https://live-api-icart.onrender.com/api",
  // baseURL: "https://test-api-icart.onrender.com/api",
  // baseURL: "http://172.20.10.3:1818/api",
  // baseURL: "http://localhost:1818/api",
  headers: {
    "x-access-token": store.getItem(store.accessTokenKey),
  },
});

export default client;
