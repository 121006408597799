import { useEffect, useState } from "react";
import delivery from "../assets/images/delivery.jpg";
import useOrderSystem from "../hooks/useOrderSystem";
import { capitalizeFirstLetter, formatNumber } from "../utils/functions";
import useQuery from "../hooks/useQuery";
import client from "../api";
import urls from "../api/urls";
import Button from "../components/Button";
import { toast } from "react-toastify";

function TrackOrder(props) {
  const { isLoading, order, getOrder } = useOrderSystem();
  const query = useQuery();
  const [id, setId] = useState(query.get("id"));

  useEffect(() => {
    if (id && id?.length === 6) getOrder(id);
  }, [id]);

  return (
    <>
      <div className="p-image">
        <img src={delivery} alt="Delivery" />
        {/* <div className="content">
          <Header transparent />
        </div> */}
      </div>

      <div className="item-details">
        <div style={{ padding: 20 }} className=" mobile-column order-tracking">
          {order ? (
            <>
              <h2 className="cormorant">Your Order</h2>
              <table className="pricing-table">
                <tr>
                  <td>Customer Information</td>
                  <td>
                    {order.customerInformation.name} -{" "}
                    {order.customerInformation.phoneNumber} (
                    {order.customerInformation.email})
                  </td>
                </tr>
                <tr>
                  <td>Delivery to: </td>
                  <td>
                    <a
                      href="http://google.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {order.deliveryTo.address}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Order ID: </td>
                  <td>{order.id}</td>
                </tr>
                <tr>
                  <td>Payment Status: </td>
                  <td>
                    <span
                      className={`s-status ${order.paymentCompleted.toString()}`}
                    >
                      {order.paymentCompleted ? "PAID" : "NOT PAID"}
                    </span>
                    {!order.paymentCompleted &&
                      order.customerInformation.email && (
                        <a
                          style={{ paddingLeft: 10 }}
                          href={
                            client.getUri() +
                            urls.products.checkout.replace(":id", order.id)
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          complete payment
                        </a>
                      )}
                  </td>
                </tr>
                <tr>
                  <td>Courier: </td>
                  <td>
                    {order.rider?.firstName} {order.rider?.lastName}
                    <a
                      style={{ paddingLeft: 10 }}
                      href="http://#"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {order.rider?.phoneNumber}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Total: </td>
                  <td>{formatNumber(order.costTotal)}</td>
                </tr>
                <tr>
                  <td>Status: </td>
                  <td>
                    <span
                      className={`s-status ${
                        order.paymentCompleted && order.status !== "delivered"
                          ? "processing"
                          : order.status
                      }`}
                    >
                      {order.status === "completed"
                        ? "ARRIVING..."
                        : order.paymentCompleted && order.status !== "delivered"
                        ? "PREPARING"
                        : order.status.toUpperCase()}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>iCart: </td>
                  <td>
                    <a
                      style={{ paddingLeft: 10 }}
                      href={`https://google.com/maps/?q=${order.kiosk?.location?.coords?.lat},${order.kiosk?.location?.coords?.lng}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {order.kiosk?.location?.label}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Order Items: </td>
                  <td>
                    {order.items.map(
                      (i, idx) =>
                        `${capitalizeFirstLetter(i.item.name)} x${
                          i.quantity
                        } ${formatNumber(i.item.price * i.quantity)}, `
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Support: </td>
                  <td>
                    <a
                      href="tel:+234 903 792 0018"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      +234 903 792 0018
                    </a>
                    <a href="mailto:info@geticart.com">info@geticart.com</a>
                  </td>
                </tr>
              </table>
              <br />
              {!order.paymentCompleted && (
                <Button
                  onClick={() => {
                    let email = null;
                    if (!order.customerInformation.email) {
                      email = prompt("Please enter your email:");
                      if (!email)
                        return toast.warn("Please enter your email address");
                    }
                    window.open(
                      client.getUri() +
                        urls.products.checkout.replace(":id", order.id) +
                        (email ? `&email=${email}` : "")
                    );
                  }}
                  title={
                    order.customerInformation.email
                      ? "Complete Payment"
                      : "Complete Payment Online"
                  }
                />
              )}
            </>
          ) : isLoading ? (
            <>
              <span>loading...</span>
            </>
          ) : (
            <>
              <input
                style={{ color: "#311e06" }}
                value={id}
                onChange={(v) => setId(v.target.value)}
                type="text"
                className="input"
                placeholder="Order ID"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default TrackOrder;

//HIR9Y6
