import { useContext, useEffect, useState } from "react";
import Button from "./Button";
import DataContext from "../contexts/DataContext";
import { formatNumber } from "../utils/functions";
import useOrderSystem from "../hooks/useOrderSystem";
import { toast } from "react-toastify";
import store from "../utils/store";

const Counter = ({ onChange, increment, decrement, count }) => {
  return (
    <div className="counter flex align-center">
      <Button onClick={increment} title="+" />
      <span className="count">{count}</span>
      <Button onClick={decrement} title="-" />
    </div>
  );
};

const Item = ({ item, updateCart }) => {
  const [count, setCount] = useState(item.quantity);
  const { price, name, description } = item;

  useEffect(() => {
    if (count <= 0) updateCart((c) => c.filter((_) => _.id !== item.id));
    else
      updateCart((c) =>
        c.map((_) => {
          if (_.id === item.id) _.quantity = count;
          return _;
        })
      );
    // eslint-disable-next-line
  }, [count]);

  return (
    <tr>
      <td className="item-info">
        <span style={{ color: "var(--white)" }}>{name}</span>
        <span>{description}</span>
      </td>
      <td className="td-counter">
        <Counter
          decrement={() => setCount(item.quantity - 1)}
          count={item.quantity}
          increment={() => setCount(item.quantity + 1)}
        />
      </td>
      <td style={{ color: "var(--white)" }}>{formatNumber(price * count)}</td>
    </tr>
  );
};

function Cart(props) {
  const [paymentMethod, setPaymentMethod] = useState("online");
  const {
    cart,
    setCart,
    deliveryAddress,
    loading: appLoading,
    deliveryCost,
    toggleLocationModal,
  } = useContext(DataContext);
  const [phoneNumber, setPhoneNumber] = useState(store.getItem("phoneNumber"));
  const { placeOrder, checkout, checkoutInfo, isLoading, order } =
    useOrderSystem();
  const [email, setEmail] = useState(store.getItem("email"));
  const [name, setName] = useState(store.getItem("name"));

  const confirmOrder = async () => {
    if (!deliveryAddress?.address)
      return toast.warn("Please select or add a delivery address");
    placeOrder({
      paymentMethod,
      customerInformation: { name, email, phoneNumber },
      deliveryTo: deliveryAddress,
      items: cart.map((c) => ({ item: c.id, quantity: c.quantity })),
    });
  };

  useEffect(() => {
    if (phoneNumber) store.setItem("phoneNumber", phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    if (email) store.setItem("email", email);
  }, [email]);

  useEffect(() => {
    if (name) store.setItem("name", name);
  }, [name]);

  return (
    <div className="cart">
      <h2 className="cormorant">Your Order</h2>
      {order ? (
        <table className="pricing-table">
          <tr>
            <td>Customer Information</td>
            <td>
              {order.customerInformation.name} -{" "}
              {order.customerInformation.phoneNumber} (
              {order.customerInformation.email})
            </td>
          </tr>
        </table>
      ) : (
        <table className="cart-table">
          {cart.map((c, idx) => (
            <Item updateCart={setCart} item={c} key={idx} />
          ))}
        </table>
      )}
      {order ? (
        <table className="pricing-table table50">
          <tr>
            <td>Order ID</td>
            <td>{order.id}</td>
          </tr>
          <tr>
            <td>Delivery to</td>
            <td>{order.deliveryTo.address}</td>
          </tr>
          <tr>
            <td>Total Cost</td>
            <td>{formatNumber(order.costTotal)}</td>
          </tr>
        </table>
      ) : !cart.length ? (
        <span className="empty-cart">your cart is empty :{"("}</span>
      ) : (
        <>
          <table className="pricing-table table50">
            <tr>
              <td>Tax</td>
              <td>
                {formatNumber(
                  (
                    0.075 * cart.reduce((p, c) => p + c.price * c.quantity, 0)
                  ).toFixed(2)
                )}
              </td>
            </tr>
            <tr>
              <td>Sub Total</td>
              <td>
                {formatNumber(
                  cart.reduce((p, c) => p + c.price * c.quantity, 0)
                )}
              </td>
            </tr>
            <tr>
              <td>Delivery to</td>
              <td style={{ cursor: "pointer" }} onClick={toggleLocationModal}>
                {deliveryAddress?.address}{" "}
                <span className="add-edit">
                  {deliveryAddress?.address ? "edit" : "add"}
                </span>
              </td>
            </tr>
            <tr>
              <td>iCart Location</td>
              <td>
                <a
                  style={{ paddingLeft: 10 }}
                  href={`https://google.com/maps/?q=${deliveryCost?.nearestKiosk?.location?.coords?.lat},${deliveryCost?.nearestKiosk?.location?.coords?.lng}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {deliveryCost?.nearestKiosk?.location?.label}
                </a>
              </td>
            </tr>
            <tr>
              <td>Payment Method</td>
              <td>
                {paymentMethod == "online"
                  ? "Online (USSD, Bank Tranfer & Card Options)"
                  : "Cash"}
              </td>
            </tr>
            <tr>
              <td>Delivery Fee</td>
              <td>{formatNumber(deliveryCost?.cost || 0)}</td>
            </tr>
            <tr>
              <td>Estimated Time</td>
              <td>{deliveryCost?.duration}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>
                {formatNumber(
                  (
                    cart.reduce((p, c) => p + c.price * c.quantity, 0) +
                    (deliveryCost?.cost || 0) +
                    0.075 *
                      (cart.reduce((p, c) => p + c.price * c.quantity, 0) +
                        (deliveryCost?.cost || 0))
                  ).toFixed(2)
                )}
              </td>
            </tr>
          </table>
          <br />
          {/* <div className="flex align-center">
            <input
              checked={paymentMethod === "cash"}
              onChange={() =>
                setPaymentMethod(paymentMethod === "online" ? "cash" : "online")
              }
              className="check"
              type="checkbox"
            />
            <span>Pay with bank transfer</span>
          </div> */}
          <br />
          {/* {paymentMethod === "cash" && (
            <div className="acct">
              <span>
                <b>5450513185</b>
              </span>
              <span>Moniepoint MFB</span>
            </div>
          )} */}
          <div className="cinfo">
            <div className="inputs flex justify-between align-center">
              <input
                value={name}
                onChange={(v) => setName(v.target.value)}
                type="text"
                className="input"
                placeholder="Your Name"
              />
              <input
                value={email}
                onChange={(v) => setEmail(v.target.value)}
                type="email"
                className="input"
                placeholder="Your Email"
              />
            </div>
            <input
              value={phoneNumber}
              onChange={(v) => setPhoneNumber(v.target.value)}
              type="text"
              className="input"
              placeholder="Your Phone Number"
            />
          </div>
          {deliveryCost?.outOfRange ? (
            <>
              <span style={{ color: "var(--alerts-danger)" }}>
                Your location is out of our coverage but you can visit our iCart
                at:{" "}
                <a
                  style={{ paddingLeft: 10 }}
                  href={`https://google.com/maps/?q=${deliveryCost?.nearestKiosk?.location?.coords?.lat},${deliveryCost?.nearestKiosk?.location?.coords?.lng}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {deliveryCost?.nearestKiosk?.location?.label}
                </a>
              </span>
              <br />
            </>
          ) : null}
          <br />
          <span style={{ color: "var(--alerts-danger)" }}>
            You can send a dispatch rider to deliver your order from the iCart
            Location:
            <a
              style={{ paddingLeft: 10 }}
              href={`https://google.com/maps/?q=${deliveryCost?.nearestKiosk?.location?.coords?.lat},${deliveryCost?.nearestKiosk?.location?.coords?.lng}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {deliveryCost?.nearestKiosk?.location?.label}
            </a>
          </span>
          <br />
          <br />
          <Button
            onClick={confirmOrder}
            disabled={isLoading || deliveryCost?.outOfRange || appLoading}
            title={isLoading ? "loading..." : "Confirm Order"}
          />
        </>
      )}
      {order && (
        <>
          <br />
          <Button
            onClick={() => {
              if (checkoutInfo) return window.open(checkoutInfo.checkoutUrl);
              else checkout(order.id);
            }}
            disabled={isLoading}
            title={
              checkoutInfo
                ? "Complete Payment"
                : isLoading
                ? "loading..."
                : "Checkout"
            }
          />
        </>
      )}
    </div>
  );
}

export default Cart;
