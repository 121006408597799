import client from "../api";
import urls from "../api/urls";

export const formatNumber = (n, pre = "₦") =>
  (pre || "") + n?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getImgUrl = (url) =>
  url
    ? client.getUri() + urls.files.baseUrl + url
    : "https://www.vocaleurope.eu/wp-content/uploads/no-image.jpg";

export const addToFormData = (object) => {
  const formData = new FormData();
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      formData.append(key, object[key]);
    }
  }
  return formData;
};

export async function reverseGeocode(lat, lng) {
  const apiKey = "AIzaSyBKHZ5C24eYH-MccKBSniBl3mT5MjBhJYY"; // Replace with your actual API key
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

  try {
    const response = await fetch(url);
    if (response.ok) {
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        return data.results[0];
        // return JSON.stringify(data.results[0].address_components[1].long_name);
        // return JSON.stringify(data.results[0].formatted_address);
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export function capitalizeFirstLetter(string) {
  if (string.length === 0) return string; // handle empty strings
  return string.charAt(0).toUpperCase() + string.slice(1);
}
